
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { CodeSurfer } from "code-surfer";
import { nightOwl } from "@code-surfer/themes";
import "prismjs/components/prism-json";
export const theme = nightOwl;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Head = makeShortcode("Head");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Head mdxType="Head">
  <title>CV Branislav Remen</title>
    </Head>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-json",
          "metastring": "3:4",
          "3:4": true
        }}>{`{ 
  "__note__": "use arrow-keys to navigate",
  "fullName": "Branislav Remeň",
  "role": "Frontend developer",
  "updatedOn": "2021-05-02T17:09:23"
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-json",
          "metastring": "2:3",
          "2:3": true
        }}>{`{
  "fullName": "Branislav Remeň",
  "role": "Frontend developer",
  "isOpenToNewPosition": false,
  "emailAddress": "branislav@remen.it",
  "location": "Bratislava, Slovakia",
  "skills": {
    "development": [
      "HTML",
      "CSS", "SCSS", "LESS", "BEM",
      "Bootstrap", "Material UI", "Storybook", "custom design systems",
      "JavaScript ES6+", "Typescript",
      "React", "Angular.js", "Angular 2",
      "Redux",
      "Web security (HTTPS, CORS, OWASP)",
      "webpack", "rollup", "gulp",
      "npm", "yarn", "bower",
      "Git", "Svn", "Github", "Gitlab", "Bitbucket",
      "Jest", "Playwright"
    ],
    "certificates": [
      "Programming in HTML5 with JavaScript and CSS3"
    ],
    "language": [
        "slovak",
        "english",
        "german"
    ]
  },
  "nextSteps": [
      "aws certified developer associate"
  ]
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "4": true,
          "className": "language-json",
          "metastring": "4"
        }}>{`{
  "fullName": "Branislav Remeň",
  "role": "Frontend developer",
  "isOpenToNewPosition": false,
  "emailAddress": "branislav@remen.it",
  "location": "Bratislava, Slovakia",
  "skills": {
    "development": [
      "HTML",
      "CSS", "SCSS", "LESS", "BEM",
      "Bootstrap", "Material UI", "Storybook", "custom design systems",
      "JavaScript ES6+", "Typescript",
      "React", "Angular.js", "Angular 2",
      "Redux",
      "Web security (HTTPS, CORS, OWASP)",
      "webpack", "rollup", "gulp",
      "npm", "yarn", "bower",
      "Git", "Svn", "Github", "Gitlab", "Bitbucket",
      "Jest", "Playwright"
    ],
    "certificates": [
      "Programming in HTML5 with JavaScript and CSS3"
    ],
    "language": [
        "slovak",
        "english",
        "german"
    ]
  },
  "nextSteps": [
      "aws certified developer associate"
  ]
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-json",
          "metastring": "5:6",
          "5:6": true
        }}>{`{
  "fullName": "Branislav Remeň",
  "role": "Frontend developer",
  "isOpenToNewPosition": false,
  "emailAddress": "branislav@remen.it",
  "location": "Bratislava, Slovakia",
  "skills": {
    "development": [
      "HTML",
      "CSS", "SCSS", "LESS", "BEM",
      "Bootstrap", "Material UI", "Storybook", "custom design systems",
      "JavaScript ES6+", "Typescript",
      "React", "Angular.js", "Angular 2",
      "Redux",
      "Web security (HTTPS, CORS, OWASP)",
      "webpack", "rollup", "gulp",
      "npm", "yarn", "bower",
      "Git", "Svn", "Github", "Gitlab", "Bitbucket",
      "Jest", "Playwright"
    ],
    "certificates": [
      "Programming in HTML5 with JavaScript and CSS3"
    ],
    "language": [
        "slovak",
        "english",
        "german"
    ]
  },
  "nextSteps": [
      "aws certified developer associate"
  ]
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-json",
          "metastring": "8:20",
          "8:20": true
        }}>{`{
  "fullName": "Branislav Remeň",
  "role": "Frontend developer",
  "isOpenToNewPosition": false,
  "emailAddress": "branislav@remen.it",
  "location": "Bratislava, Slovakia",
  "skills": {
    "development": [
      "HTML",
      "CSS", "SCSS", "LESS", "BEM",
      "Bootstrap", "Material UI", "Storybook", "custom design systems",
      "JavaScript ES6+", "Typescript",
      "React", "Angular.js", "Angular 2",
      "Redux",
      "Web security (HTTPS, CORS, OWASP)",
      "webpack", "rollup", "gulp",
      "npm", "yarn", "bower",
      "Git", "Svn", "Github", "Gitlab", "Bitbucket",
      "Jest", "Playwright"
    ],
    "certificates": [
      "Programming in HTML5 with JavaScript and CSS3"
    ],
    "language": [
        "slovak",
        "english",
        "german"
    ]
  },
  "nextSteps": [
      "aws certified developer associate"
  ]
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-json",
          "metastring": "21:23",
          "21:23": true
        }}>{`{
  "fullName": "Branislav Remeň",
  "role": "Frontend developer",
  "isOpenToNewPosition": false,
  "emailAddress": "branislav@remen.it",
  "location": "Bratislava, Slovakia",
  "skills": {
    "development": [
      "HTML",
      "CSS", "SCSS", "LESS", "BEM",
      "Bootstrap", "Material UI", "Storybook", "custom design systems",
      "JavaScript ES6+", "Typescript",
      "React", "Angular.js", "Angular 2",
      "Redux",
      "Web security (HTTPS, CORS, OWASP)",
      "webpack", "rollup", "gulp",
      "npm", "yarn", "bower",
      "Git", "Svn", "Github", "Gitlab", "Bitbucket",
      "Jest", "Playwright"
    ],
    "certificates": [
      "Programming in HTML5 with JavaScript and CSS3"
    ],
    "language": [
        "slovak",
        "english",
        "german"
    ]
  },
  "nextSteps": [
      "aws certified developer associate"
  ]
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-json",
          "metastring": "24:28",
          "24:28": true
        }}>{`{
  "fullName": "Branislav Remeň",
  "role": "Frontend developer",
  "isOpenToNewPosition": false,
  "emailAddress": "branislav@remen.it",
  "location": "Bratislava, Slovakia",
  "skills": {
    "development": [
      "HTML",
      "CSS", "SCSS", "LESS", "BEM",
      "Bootstrap", "Material UI", "Storybook", "custom design systems",
      "JavaScript ES6+", "Typescript",
      "React", "Angular.js", "Angular 2",
      "Redux",
      "Web security (HTTPS, CORS, OWASP)",
      "webpack", "rollup", "gulp",
      "npm", "yarn", "bower",
      "Git", "Svn", "Github", "Gitlab", "Bitbucket",
      "Jest", "Playwright"
    ],
    "certificates": [
      "Programming in HTML5 with JavaScript and CSS3"
    ],
    "language": [
        "slovak",
        "english",
        "german"
    ]
  },
  "nextSteps": [
      "aws certified developer associate"
  ]
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-json",
          "metastring": "30:32",
          "30:32": true
        }}>{`{
  "fullName": "Branislav Remeň",
  "role": "Frontend developer",
  "isOpenToNewPosition": false,
  "emailAddress": "branislav@remen.it",
  "location": "Bratislava, Slovakia",
  "skills": {
    "development": [
      "HTML",
      "CSS", "SCSS", "LESS", "BEM",
      "Bootstrap", "Material UI", "Storybook", "custom design systems",
      "JavaScript ES6+", "Typescript",
      "React", "Angular.js", "Angular 2",
      "Redux",
      "Web security (HTTPS, CORS, OWASP)",
      "webpack", "rollup", "gulp",
      "npm", "yarn", "bower",
      "Git", "Svn", "Github", "Gitlab", "Bitbucket",
      "Jest", "Playwright"
    ],
    "certificates": [
      "Programming in HTML5 with JavaScript and CSS3"
    ],
    "language": [
        "slovak",
        "english",
        "german"
    ]
  },
  "nextSteps": [
      "aws certified developer associate"
  ]
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`THANK YOU !`}</h1>
    <h6>{`Created using mdx-deck and code-surfer.`}</h6>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;